.footer {
  margin-top: 2rem;
  padding-bottom: 1rem;
  font-size: 14px;
  color: #E0E4E8;
}

.footer .footer-bold {
  font-weight: 700;
}

@media (min-width: 1200px) {
  .footer {
    position: fixed;
    bottom: 14px;
    padding: 5px;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
}

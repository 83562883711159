@import "./CustomIcon.css";
@import "./LinksMenu.css";
@import "./Footer.css";
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap");
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: #0B192C;
  border-radius: 3px;
}

::-webkit-scrollbar-track-piece {
  background: #313131;
}

::-webkit-scrollbar-thumb:hover {
  background: #1A2A40;
}

body {
  background: black;
  background: -webkit-gradient(linear, left top, right top, from(#050505), to(#1b1b1b));
  background: linear-gradient(90deg, #050505 0%, #1b1b1b 100%);
  color: #E0E4E8;
  padding-top: 12px;
  padding-bottom: 32px;
  font-size: 18px;
  height: 100vh;
  font-family: 'JetBrains Mono', monospace;
}

@media (min-width: 768px) {
  body {
    padding-top: 32px;
  }
}

#root {
  height: 100%;
}

/*Animated underline*/
.animated-link {
  text-decoration: none;
  position: relative;
}

.animated-link::before, .animated-link::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  border-top-width: 2px;
  border-top-color: #ffffff5f;
}

.animated-link::after {
  border-top-style: solid;
  width: 0;
  -webkit-transition: width .2s ease;
  transition: width .2s ease;
}

.animated-link:hover::after {
  width: 100%;
}

.scrollable-section {
  height: 100vh;
  overflow-y: auto;
}

.typetext {
  font-size: 38px !important;
  font-weight: 800 !important;
  word-spacing: -10px;
}

.orange-color {
  color: #FF6500;
}

.blue-color {
  color: #0B192C;
}

p {
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 200;
}

h1 {
  font-size: 48px;
  font-weight: 700;
}

h1 .title-span {
  color: #FF6500;
}

h2 {
  font-size: 18px;
  margin-top: 20px;
}

h3 {
  text-align: center;
  font-size: 30px;
  font-weight: normal;
  margin: 16px auto;
}

@media (min-width: 1200px) {
  .fixed {
    position: fixed;
    max-width: 33%;
  }
}

@media (min-width: 1200px) and (max-height: 630px) {
  .fixed {
    position: relative;
    max-width: 100%;
  }
}

.dropdown {
  background-color: transparent;
  border: none;
  margin-left: 10px;
}

.wiggle {
  -webkit-animation: wiggle 2s linear infinite;
          animation: wiggle 2s linear infinite;
}

.rotate-360 {
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.rotate-180 {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.orange-link {
  color: #FF6500;
  font-weight: 500;
}

.orange-link:hover {
  color: #FF6500;
}

.orange-link::before, .orange-link::after {
  border-top-color: #FF6500;
}

/* Keyframes */
@-webkit-keyframes wiggle {
  0%, 7% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
  15% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
  }
  20% {
    -webkit-transform: rotateZ(10deg);
            transform: rotateZ(10deg);
  }
  25% {
    -webkit-transform: rotateZ(-10deg);
            transform: rotateZ(-10deg);
  }
  30% {
    -webkit-transform: rotateZ(6deg);
            transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
            transform: rotateZ(-4deg);
  }
  40%, 100% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
}
@keyframes wiggle {
  0%, 7% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
  15% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
  }
  20% {
    -webkit-transform: rotateZ(10deg);
            transform: rotateZ(10deg);
  }
  25% {
    -webkit-transform: rotateZ(-10deg);
            transform: rotateZ(-10deg);
  }
  30% {
    -webkit-transform: rotateZ(6deg);
            transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
            transform: rotateZ(-4deg);
  }
  40%, 100% {
    -webkit-transform: rotateZ(0);
            transform: rotateZ(0);
  }
}

@media (min-width: 1200px) {
  .small-screen {
    display: none;
  }
}

.large-screen {
  display: none;
}

@media (min-width: 1200px) {
  .large-screen {
    display: block;
  }
}

.introduction .introduction--title {
  line-height: 2.9rem;
  margin-bottom: 3rem;
}

.introduction h2 {
  margin-bottom: 3rem;
}

.introduction p {
  margin-top: 16px;
  margin-bottom: 3rem;
}

@media (min-width: 1200px) {
  .introduction {
    margin-bottom: 0rem;
  }
  .introduction h2 {
    margin-bottom: 0rem;
  }
  .introduction p {
    margin-bottom: 0rem;
  }
}

@media (min-width: 1400px) {
  .introduction {
    max-width: 600px;
  }
}

.content--grid {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .content--grid {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
    grid-row: auto auto;
    gap: 2rem;
    row-gap: 0;
  }
}

#projects {
  margin-top: 3rem;
}

#projects h3 {
  margin-bottom: 1.5rem;
}

#projects .project {
  background-color: #040a12;
  padding: 24px;
  margin-top: 42px;
  border-radius: 10px;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  overflow: hidden;
  position: relative;
  min-height: 20rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-decoration: none;
  /*Project tools icon*/
}

#projects .project .project--img {
  max-width: 100%;
  position: absolute;
  -webkit-mask-image: -webkit-gradient(linear, left bottom, left top, color-stop(47%, transparent), color-stop(105%, #000));
  -webkit-mask-image: linear-gradient(to top, transparent 47%, #000 105%);
  mask-image: -webkit-gradient(linear, left bottom, left top, color-stop(47%, transparent), color-stop(105%, #000));
  mask-image: linear-gradient(to top, transparent 47%, #000 105%);
  top: 0;
  left: 0;
  -webkit-transition: all ease-in-out 250ms;
  transition: all ease-in-out 250ms;
}

#projects .project .project--tools {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
  margin-top: 6px;
  margin-bottom: 6px;
  /*Icon for tool*/
}

#projects .project .project--tools .project--tool {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: end;
  gap: 8px;
  background-color: #0b192c4b;
  border-radius: 8px;
  padding: 7px 7px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 16px;
  color: #E0E4E8;
}

#projects .project .project--tools .project--tool img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  max-width: 100%;
  max-height: 1.6rem;
  display: block;
}

#projects .project .project--description {
  color: #e0e4e8d2;
  word-spacing: -2px;
  font-size: 14px;
}

@media (min-width: 1200px) {
  #projects .project .project--content {
    position: relative;
    top: 48px;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
  }
  #projects .project:hover .project--content {
    top: 10px;
  }
  #projects .project:hover .project--img {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
  }
}

#projects .project .title {
  position: relative;
  color: #E0E4E8;
  font-size: 20px;
  font-weight: 600;
  -webkit-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  z-index: 2;
}

#projects .project .title:hover {
  color: #e0e4e8d1;
  text-decoration: none;
}

#projects .project p {
  margin-bottom: 10px;
  margin-top: 0;
}

#projects .project a {
  color: #E0E4E8;
  text-decoration: none;
}

#projects .project a:hover {
  text-decoration: underline;
}

#projects .project svg {
  margin-left: 4px;
}

#projects .project .project--link {
  background-color: #E0E4E8;
  border-radius: 7px;
  color: black;
  font-size: 12px;
  padding: 4px 8px;
  font-weight: 300;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

#projects .project .project--link i {
  margin-left: 3px;
}

#projects .project .project--link:hover {
  text-decoration: none;
  background-color: #e0e4e8d1;
}

@media (min-width: 1200px) {
  #projects .project {
    min-height: 18rem;
  }
}

#projects .nav-slider button {
  letter-spacing: -1px;
  background-color: transparent;
  color: #ffffff5f;
  border: none;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  font-size: 24px;
}

#projects .nav-slider .nav-slider--active {
  color: #E0E4E8;
}

#projects .nav-slider .nav-slider--active::before, #projects .nav-slider .nav-slider--active::after {
  border-top-color: #E0E4E8;
}

#projects .nav-slider .nav-slider--projects {
  margin-right: 1rem;
}

@media (min-width: 1200px) {
  #projects {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
    grid-column: 2/3;
    padding-bottom: 4rem;
    margin-top: 0;
  }
}

.skills-slide {
  min-height: 85vh;
}

/*Skills*/
.skills {
  margin-top: 2.1rem;
  padding-left: 0;
  text-align: start;
  width: 100%;
}

.skills li {
  text-align: start;
}

@media (min-width: 1200px) {
  .skills {
    margin-top: 1rem;
  }
}

@-webkit-keyframes slideIn {
  from {
    -webkit-transform: translateX(16px);
            transform: translateX(16px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

@keyframes slideIn {
  from {
    -webkit-transform: translateX(16px);
            transform: translateX(16px);
  }
  to {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }
}

.projects-slide {
  -webkit-transition: all ease-out 250ms;
  transition: all ease-out 250ms;
}

.projects-slide.show {
  -webkit-animation: slideIn 0.5s ease forwards;
          animation: slideIn 0.5s ease forwards;
}

/*Line divider*/
.line-divider {
  margin-bottom: 8px;
  margin-top: 8px;
  --b: 1px;
  /* control the border thickness */
  --w: 80px;
  /* control the width of the line*/
  --g: 0px;
  /* control the gap */
  --c: #242424;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 16px;
  -o-border-image: linear-gradient(#0000 calc(50% - var(--b)/2), var(--c) 0 calc(50% + var(--b)/2), #0000 0) 1/0 var(--w)/calc(var(--w) + var(--g));
     border-image: -webkit-gradient(linear, left top, left bottom, from(#0000), color-stop(0, var(--c)), color-stop(0, #0000)) 1/0 var(--w)/calc(var(--w) + var(--g));
     border-image: linear-gradient(#0000 calc(50% - var(--b)/2), var(--c) 0 calc(50% + var(--b)/2), #0000 0) 1/0 var(--w)/calc(var(--w) + var(--g));
}

.nav-menu {
  position: fixed;
  right: 5rem;
  top: 0rem;
  margin: 0;
}

.nav-menu button {
  font-weight: 500;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 1rem;
  margin: 1rem;
  background-color: #0B192C;
  color: #E0E4E8;
}

.nav-menu button:hover {
  color: #FF6500;
}

.nav-menu .active {
  color: #FF6500;
}

#themeIcon {
  position: fixed;
  display: none;
  max-width: 4rem;
  right: 5rem;
  top: 10rem;
  -webkit-animation: ghost-float 3s infinite ease-in-out;
          animation: ghost-float 3s infinite ease-in-out;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 99;
}

@media (min-width: 768px) {
  #themeIcon {
    /*to show Ghost, display block here*/
    display: block;
    max-width: 3rem;
    right: 2.5rem;
  }
}

@media (min-width: 1600px) {
  #themeIcon {
    right: 5rem;
  }
}

#themeIcon:hover #leftEye {
  -webkit-animation: closeEye 10s infinite;
          animation: closeEye 10s infinite;
  -webkit-transition: -webkit-animation 0.3s ease;
  transition: -webkit-animation 0.3s ease;
  transition: animation 0.3s ease;
  transition: animation 0.3s ease, -webkit-animation 0.3s ease;
  /* Add transition for smooth effect */
}

@-webkit-keyframes closeEye {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%, 100% {
    d: path("M172.483,217.034c-27.632,0-50.11,0-50.11,0s22.478,0,50.11,0c27.632,0,50.11,0,50.11,0 S200.115,217.034,172.483,217.034z");
  }
}

@keyframes closeEye {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%, 100% {
    d: path("M172.483,217.034c-27.632,0-50.11,0-50.11,0s22.478,0,50.11,0c27.632,0,50.11,0,50.11,0 S200.115,217.034,172.483,217.034z");
  }
}

@-webkit-keyframes ghost-float {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-13px);
            transform: translateY(-13px);
  }
}

@keyframes ghost-float {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-13px);
            transform: translateY(-13px);
  }
}
